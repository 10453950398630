/* Reset margins and padding for the entire document */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    /* overflow: hidden; */
    background-color: black;
    color: white;
  }
  
  /* Universal box-sizing */
  * {
    box-sizing: border-box; /* Ensures padding and border are included in width/height */
  }

  .logo {
    display: block;
    margin: 0 auto; /* Center the logo horizontally */
    margin-top: -1.2rem;
    width: 135px; /* Adjust the size of the logo as needed */
    height: auto;
  }

  h1 {
    margin-top: 7rem;
    margin-bottom: 5rem;
  }
  
  .header {
    background-image: 
      linear-gradient(to bottom, rgba(0, 0, 0, 0.83), rgba(0, 0, 0, 0)), /* Darker at the top, fading out */
      url('../images/burger.jpg');
    background-size: cover;
    background-position: center;
    color: #ffffff;
    padding-top: 0px; 
    height: 62.5vh;
    border-bottom: 3px solid #e8491d;
    text-align: center;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  
  
  .container h1 {
    font-size: 2.5rem;
    font-weight: 800;
  }
  
  .container {
    width: 80%;
    margin: auto;
    overflow: hidden;
    padding: 20px;
  }
  
  .location-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .location-item {
    margin: 10px;
  }
  
  .restaurant-button {
    background-color: #e8491d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .restaurant-button:hover {
    background-color: #d7370a;
  }
  
  .restaurant-button:focus {
    outline: none;
  }
  
  /* p{
    width: 100%;
    white-space: nowrap;
    font-weight: 600;
  } */

  p {
    width: 100%; /* Make the paragraph take full width */
    white-space: normal; /* Ensure text wraps to a new line when needed */
    font-weight: 600;
    text-align: center; /* Center align the text */
    padding: 0 10px; /* Add some padding to prevent text from being too close to the edges */
  }
  
  h2{
    margin-left: 1.2rem;
  }
  
  button{
    font-weight: bold;
  }
  
  .landing-page{
    align-content: center;
    background-color: black;
  }

  /* Media query for smaller screens */
@media (max-width: 600px) {
  p {
      font-size: 0.9rem; /* Reduce font size slightly for small screens */
      padding: 0 5px; /* Adjust padding for smaller devices */
  }
}

h2 {
  margin-left: 1.2rem;
}

button {
  font-weight: bold;
}

.landing-page {
  align-content: center;
  background-color: black;
}